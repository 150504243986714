/* You can add global styles to this file, and also import other style files */
* {
    margin: 0;
    padding: 0;
}

body {
    background: url("../assets/main-background.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    font-size: 16px;
    font-family: system-ui;
}

h2 {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 2.5rem;
}

h3 {
    font-size: 1.625rem;
    font-weight: 400;
    line-height: 2.5rem;
}

h4 {
    color: #052b4e;
    font-size: var(--h4-font-size);
    font-weight: 400;
    line-height: 2.25rem;
}
