.flex-center {
    align-items: center;
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.flex-end {
    justify-content: flex-end;
}

.fit-content {
    width: fit-content;
}

.flex-row-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-column-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.error {
    color: #ec4a36;
}

.success {
    color: #00a700;
}
